.productList {
  display: table;
  border-spacing: 10px;
  border: solid black 1px;
  padding-inline-start: 0;
  padding-inline: 5px;
  
}
.productLine {
  display: table-row;
}

productLine {
  height: 20px;
}

.productList .productLine:nth-child(odd) {
  background-color: lightgray;
}
.barcode {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  display: table-cell;
}
.prodName {
  font-weight: bold;
  display: table-cell;
}