footer {
  background-color: #292929;
  nav {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      background-color: #333333;
    }

    li {
      float: left;
    }

    li a {
      display: block;
      color: white;
      text-align: center;
      padding: 16px;
      text-decoration: none;
    }

    li a:hover {
      background-color: #111111;
    }
  }
}

.white {
  color: white;
}
.center {
  text-align: center;
}